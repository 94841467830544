import React, {useState,useContext} from 'react';
import {Box, Button} from '@material-ui/core';

import axios from 'axios';

import MessageContext from '../../../messaging/message';
import LoadContext from '../../../loading/load';
import DadosPessoais from './DadosPessoais.js';
import Entrega from './Entrega.js';
import Sabores from './Sabores.js';
import { useListaPedido } from '../Context';
import getMainUrl from '../../../utilJs/utilUrl.js';
import getToken from '../../../utilJs/utilToken.js';
import StatusPagamento from './StatusPagamento.js';

const EditarPedido = (params) => {

  const {pedidos,setPedidos} = useListaPedido();
  const {bairros} = useListaPedido();
  const {loadValue} = useContext(LoadContext);
  const {setEditionOption} = useListaPedido();
  const {msgErro,msgInfo} = useContext(MessageContext);
  const index = pedidos.findIndex(({id}) => id === params.idPedido); 
  const [pedido]  = useState(getPedido());

  function submit() {

    loadValue(true);
    if (valida()) {
      const url = getMainUrl() + 'venda/upload';
      axios( {method: 'POST', url: url , headers: {'token' : getToken()}, data: {pedido}})
      .then((response) => {
          setPedidos(() => {
            index > -1 ? pedidos[index] = response.data : pedidos.unshift(response.data);
            return pedidos;
          });
        setEditionOption(false);
        msgInfo("Tudo ok com o pedido de " + pedido.nomeCliente);
      }).catch(error =>  {
          msgErro('alteracao nao foi realizada (ou existem problemas de conexao ou outra pessoa alterou este pedido) - favor recarregar a consulta' +  error.message );
      }); 
    }
    loadValue(false);
  }

  function valida() {

    if (pedido.qtdEspecial > 0 && pedido.observacao.length === 0) {
      msgErro("Favor preencher observacao da pizza ESPECIAL");
      document.getElementById("observacao").focus();
      return false;
    }  

    if (pedido.qtdEspecial < 0 || pedido.qtdMista < 0 || pedido.qtdMarguerita < 0 || pedido.qtdPresunto < 0) {
      msgErro("Favor corrigir quantidade negativa de pizza");
      return false;
    }

    if (pedido.qtdEspecial+pedido.qtdMista+pedido.qtdMarguerita+pedido.qtdPresunto <= 0) {
      msgErro("Favor Escolher os sabores das pizzas");
      return false;
    }

    if (pedido.nomeCliente.length === 0) {
      msgErro("Favor preencher o nome do CLIENTE");
      document.getElementById("nomeCliente").focus();
      return false;
    }

    if (!pedido.retirarAlbergue && pedido.idBairroEntrega == '3') {
      msgErro("Favor preencher o bairro de entrega");
      document.getElementById("idBairroEntrega").focus();
      return false;
    }

    return true;
  }

  function getPedido() {

    return (index >= 0) ? 
          JSON.parse(JSON.stringify(pedidos[index]))
          :
          getNovoPedido();

  }

  function getNovoPedido() {

    let pedido = {nomeCliente:"", enderecoEntrega: "ALBERGUE J.I.", idBairroEntrega: "3", statusPagamento: "Receber",
                  qtdMista:"0", qtdMarguerita:"0", qtdPresunto: "0", qtdEspecial: "0",observacao: "",
                  numeroEnderecoEntrega: "", complementoEndereco: "", confirmado: "true", idRoteiro: "0",
                  telefoneCelular:"", telefoneFixo:"", observacaoEntrega:"", retirarAlbergue: "true", excluirPedido: "false"};

    return pedido;                   

  }

  return (
    <Box marginLeft={0.5} marginRight={0.5}>
        <form>    
            <DadosPessoais pedido={pedido}/>
            <br/>
            <Sabores pedido={pedido}/>
            <br/><br/>
            <Entrega pedido={pedido} bairros={bairros}/>
            <br/><br/>
            <StatusPagamento pedido={pedido}/>
            <br/><br/>
            <Button variant='outlined' onClick={submit}>SALVAR</Button>
            &nbsp; &nbsp;&nbsp; &nbsp;
            <Button variant='outlined' onClick={() => {setEditionOption(false);}}>DESFAZER</Button>
            <br/><br/>
        </form>
    </Box>
  );
}

export default EditarPedido;