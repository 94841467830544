import React, {useState,useContext} from 'react';

import {Box,Button,Typography,Card,CardActions,CardContent} from '@material-ui/core';

import axios from 'axios';

import MessageContext from '../../../messaging/message';
import getToken from '../../../utilJs/utilToken.js';
import getMainUrl from '../../../utilJs/utilUrl.js';
import styles from '../../../utilJs/css.js';
import useStyles from '../../../utilJs/styles.js';
import { useSearch } from '../../../searching/search';
import { useListaPedido } from '../Context';
import EditarPedido from '../EditarPedido';

const  Pedido = ()  => {

    const {msgErro,msgInfo} = useContext(MessageContext);
    const {pedidos,setPedidos} = useListaPedido();
    const {editionOption,setEditionOption} = useListaPedido();
    const [filtro,setFiltro]= useState(0);
    const [idPedido,setIdPedido ]= useState(0);
    const classes = useStyles();
    const {searchTxt} = useSearch();

    const ItemListaPedido = () => {

        return (
            pedidos.map(item => {
                    if (getExibirPedido(filtro,item)) {
                        return (
                                <Card key={item.id} className={classes.card}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                    {item.nomeCliente}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color='textSecondary'>
                                        {item.enderecoCompleto}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color='textSecondary'>
                                        {item.descricaoPedido}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color='textSecondary'>
                                        {item.telefoneCelular + ' '  + item.observacaoEntrega}
                                    </Typography>

                                </CardContent>
                                <CardActions>
                                    {item.situacaoCaixa == 'ABERTO' ?
                                        <div>
                                        <Button className='outlined' onClick={() => alterarPedido(item.id,getTextoButtonAcaoPedido(item.confirmado))}>{getTextoButtonAcaoPedido(item.confirmado)}</Button>
                                        <Button className='outlined' onClick={() => editar(item.id)}>ALTERAR</Button> 
                                        <Button className='outlined' onClick={() => alterarPedido(item.id,'retirarCliente')}>EXCLUIR</Button>
                                        </div>
                                        :
                                        <div>
                                        <Button className='outlined' disabled={true}>VENDAS ENCERRADAS</Button>     
                                        </div>
                                    }
                                </CardActions>    
                                </Card>
                        )
                    }
                }
                )
            );

    }

    function getTextoButtonAcaoPedido(confirmado) {
        return (confirmado) ? 'CANCELAR' : 'CONFIRMAR';
    } 

    function editar(idPedido) {

        if (autorizaEdicao(idPedido)) {
            setEditionOption(true);
            setIdPedido(idPedido);
        } else {
            msgErro("Caixa Fechado - operacao nao permitida");
        }
    }

    function autorizaEdicao(idPedido) {

        if (idPedido == 0) {
            if (pedidos.length == 0) {
                return true;
            } else {
                return pedidos[0].situacaoCaixa === 'ABERTO';
            }
        } else {
            return pedidos[pedidos.findIndex(({id}) => id === idPedido) ].situacaoCaixa === 'ABERTO';  
        }

    }

    function alterarPedido(idPedido,operacao) {

        const index = pedidos.findIndex(({id}) => id === idPedido);

        if (index === -1) {
            msgErro("pedido nao encontrado na lista (" + idPedido + ")");
            return null;
        }

        var pedido =  pedidos[index];

        if (pedido === null) return;

        if (!pedido.situacaoCaixa === 'ABERTO') {
            msgErro("Caixa Fechado - Alteracao nao permitida")
            return;
        } 
        
        if (operacao === 'retirarCliente')  {
            pedido.excluirPedido = true;
        }  else {
            pedido.confirmado = !pedido.confirmado;
        }

        const url = getMainUrl() + 'venda/upload';
        axios( {method: 'POST', url: url , headers: {'token' : getToken()}, data: {pedido}})
        .then((response) => {
            setPedidos(
                () => {
                    if (operacao === 'retirarCliente') {
                        pedidos.splice(index,1);
                    } else {
                    pedidos[index] = response.data;
                    }
                    return pedidos;
                }
            )
            msgInfo(getTextoResultOK(operacao) + ' ' + pedido.nomeCliente);
            }).catch(error =>  {
            msgErro('alteracao nao foi realizada ' +  error.message );
        }); 

    }

    function getTextoResultOK(operacao) {

        if (operacao === 'retirarCliente') return 'RETIRADO DA LISTA';

        return (operacao.toLowerCase() === 'confirmar') ?  'CONFIRMADO' : 'CANCELADO';

    }

    function getExibirPedido(filtro,pedido) {

        if (searchTxt.length > 0 && 
            !pedido.nomeCliente.toLowerCase().includes(searchTxt.toLowerCase()) && 
            !pedido.enderecoCompleto.toLowerCase().includes(searchTxt.toLowerCase()) &&
            !pedido.observacaoEntrega.toLowerCase().includes(searchTxt.toLowerCase())  &&
            !pedido.observacao.toLowerCase().includes(searchTxt.toLowerCase())
            ) {
            return false;
        }

        if (filtro === 0 && !pedido.confirmado) return true;
        if (filtro === 1 && pedido.confirmado) return true;
        return false;
    }
    
    function getStyleFiltroAtivo(botao) {
        if (botao === filtro) return styles.buttonAtivo;
        return styles.fonteBranca;
    }

    if (editionOption) {
        return <EditarPedido idPedido={idPedido}  modoEdicao={setEditionOption}/>
    } else {
        return (
            <div>
                <div style={styles.fixoTop}>
                    <Button style={getStyleFiltroAtivo(0)} onClick={() => {setFiltro(0)}}>CONFIRMAR</Button>
                    <Button style={getStyleFiltroAtivo(1)} onClick={() => {setFiltro(1)}}>CONFIRMADO</Button>
                    <Button style={getStyleFiltroAtivo(2)} onClick={() => editar(0)}>NOVO</Button>
                </div>
                <Box className={classes.cards2}>
                <ItemListaPedido/>
                </Box>
            </div>    
        )
    }
}

export default Pedido;