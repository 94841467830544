  const styles = {
    avatar: {color: '#fff', backgroundColor: "rgb(106,90,205)",borderRadius: 0},
    card: {flex: '0',marginTop: '0.1em', marginRight: '0.2em',marginLeft: '0.2em'},
    flexContainer: {display: 'flex',backgroundColor: "rgb(72,84,180)"},
    icon: { float: 'right', width: 40, height: 40, padding: 32, color: '#FFCA28' },
    buttonAtivo: {color: 'rgb(255,255,0)', backgroundColor: "rgb(72,84,180)"},
    fonteBranca: {color: '#fff'},
    dashboard: {color: '#fff', backgroundColor: "rgb(106,90,205)",fontSize: "50px"},
    fixoTop: {zIndex: '1',position: 'fixed',top: '7.5%', width: "100%",backgroundColor: "rgb(72,84,180)"},
    posBarra: {top: '10%', position: 'fixed'},
    cardTeste: { borderLeft: 'solid 4px #6a5acd', flex: '1', borderBottom: '3', marginRight: '0.5em'},
    container: { display: 'flex'},
    divInline: { display: 'inline'},
    divNone: {display: 'none'}
  };

  export default styles;