import {React,useState} from 'react';
import {TextField} from '@material-ui/core';
import {Radio,RadioGroup,FormControl,FormControlLabel,FormLabel,NativeSelect,InputLabel} from '@material-ui/core';

import { useListaPedido } from '../Context';


const Entrega = (params) => {

    const [tipoEntrega] = useState(getTextoTipoEntrega(params.pedido.retirarAlbergue));
    const [showEndereco,setShowEndereco] = useState(!params.pedido.retirarAlbergue);
    const {bairros} = useListaPedido();

    const handleChange = event => {
        params.pedido[event.target.id] = event.target.value;
    }    

    const handleTipoEntrega = event => {
        params.pedido.retirarAlbergue = (event.target.value === 'ALBERGUE');
        setShowEndereco(!params.pedido.retirarAlbergue);
    }

    function getTextoTipoEntrega(retirarAlbergue) {
        return (retirarAlbergue) ? 'ALBERGUE' : 'ENTREGA';
    }

    const Endereco = () => {

        return (
            <div>
                <br/>
                <TextField  defaultValue={params.pedido.enderecoEntrega}
                            onChange={handleChange} 
                            multiline={true} required  fullWidth={true}
                            variant='outlined' floatinglabeltext="Endereco"  id="enderecoEntrega" label="Endereco" />
                <br/><br/>            
                <TextField  defaultValue={params.pedido.numeroEnderecoEntrega} 
                            onChange={handleChange} 
                            variant='outlined' floatinglabeltext="Numero" id="numeroEnderecoEntrega" label="Numero"/>
                &nbsp;&nbsp;&nbsp;
                <TextField  defaultValue={params.pedido.complementoEndereco} 
                            onChange={handleChange} 
                            multiline={true}
                            variant='outlined' floatinglabeltext="Complemento" id="complementoEndereco" label="Complemento / Endereco" />
                <br/><br/>
                <Bairro/>
            </div>
        );
    
    }

    const Bairro = () => {
        return (<FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Bairro
            </InputLabel>
            <NativeSelect defaultValue={params.pedido.idBairroEntrega} id="idBairroEntrega" onChange={handleChange} htmlFor="uncontrolled-native">
               {bairros.map(bairro => {
                   return ( <option key={bairro.id} value={bairro.id}>{bairro.nome}</option>)
               })} 
            </NativeSelect>
          </FormControl>);
    }

    return (
        <div>
            <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">TIPO DE ENTREGA</FormLabel>
                  <RadioGroup defaultValue={tipoEntrega} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                    <FormControlLabel onChange={handleTipoEntrega} value="ALBERGUE" control={<Radio />} label="ALBERGUE" />
                    <FormControlLabel onChange={handleTipoEntrega} value="ENTREGA" control={<Radio />} label="ENTREGA" />
                  </RadioGroup>
            </FormControl>          
            <br/><br/>
            <div style={{display: (showEndereco) ? 'inline' : 'none'}}>
                <Endereco/>
            </div>

        </div>
    );
}

export default Entrega;