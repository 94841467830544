import { makeStyles } from "@material-ui/core";

export  default makeStyles((theme) => ({
    cards: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginLeft: '10px',
        marginTop: '10px',
        backgroundColor: 'rgb(216,216,216)'
      //  justifyContent: 'space-around'
    },
    cards2: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginTop: '10px',
        backgroundColor: 'rgb(216,216,216)',
      //  justifyContent: 'space-around'
    },

    wcards: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
      //  justifyContent: 'space-around'
    },

    card: {
        width: '340px',
        flexDirection: 'column',
       // justifyContent: 'space-between',
      //  gap: '10px',
        marginLeft: '5px',
        marginTop: '5px'
    },
    cardFull: {
        width: '100%',
        flexDirection: 'column',
       // justifyContent: 'space-between',
        gap: '10px',
        flex: '0',marginTop: '0.5em', marginRight: '0.5em',marginLeft: '0.5em'
    },

    photoLocation: {
        width: '5%'
    },
    buttonArea: {
        margin: '10px auto',
        display: 'flex',
        //justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },

    top10Left10: {
        marginTop: '10px',
        marginLeft: '10px',
    },

    top10Right10: {
        marginTop: '10px',
        marginRight: '10px',
    },

    top10: {
        marginTop: '10px',
    }

})
);