import React, {useState,useEffect,useContext} from 'react';
import {Card,CardHeader,CardContent} from '@material-ui/core';
import axios from 'axios';

import MessageContext from '../../messaging/message';
import LoadContext from '../../loading/load';
import getToken from '../../utilJs/utilToken.js';
import getMainUrl from '../../utilJs/utilUrl.js';

import VendaPorVendedor from './VendaPorVendedor';
import styles from '../../utilJs/css.js';

const  Venda = (props)  => {

    const token = getToken();
    const {msgErro,msgInfo} = useContext(MessageContext);
    const {loadValue} = useContext(LoadContext);
    
    const [dados,setData] = useState([]);

    useEffect( () => { buscar(); },[]);

    const buscar = async (cb=()=>{}) => {
            loadValue(true);
            try {
                const url = getMainUrl() + 'venda/buscarCartaoInicial';
                await new Promise(r => setTimeout(r, 5000));
                const dto = await axios( {method: 'GET', url: url , headers: {'token' : token}});
                setData(dto.data);
                cb();
            } catch(e) {
                if (e.response) {
                    if (e.response.status === 401) {
                    localStorage.removeItem('token');    
                    msgInfo('Sessão Expirada - Faça Login novamente');
                    }
                }
                else {
                    msgErro('Erro na obtencao dos dados (' + e + ')');
                }
                cb(e);
            }
            loadValue(false);
    };

    return (
        <div>
            <Card style={styles.card}>
            <CardHeader
            title={dados.diaDaPizza}
            subtitle={dados.situacaoCaixa}
            />
            <CardContent>
                <p>Você vendeu  <b><font size="5">{dados.qtdTotalVendedor}</font></b> pizzas
                    sendo {dados.qtdMistaVendedor} mistas, &nbsp;
                    {dados.qtdMargueritaVendedor} margueritas, &nbsp;
                    {dados.qtdPresuntoVendedor} de presunto e &nbsp;
                    {dados.qtdEspecialVendedor} especiais <br/><br/>
                    No total nós já vendemos  <b><font size="5">{dados.qtdTotal}</font></b> pizzas
                    sendo {dados.qtdMista} mistas, &nbsp;
                    {dados.qtdMarguerita} margueritas, &nbsp;
                    {dados.qtdPresunto} de presunto e &nbsp;
                    {dados.qtdEspecial} especiais  <br/><br/>
                </p>
                <VendaPorVendedor/>
            </CardContent>
            </Card>
        </div>
    )
}

export default Venda;
