import React,  {useState,useContext} from 'react';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import MessageContext from '../../messaging/message';
import LoadContext from '../../loading/load';
import getMainUrl from '../../utilJs/utilUrl.js';
import Venda from '../Venda/index';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        simmc.com.br
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {

  const classes = useStyles();

  const [usuario,setUsuario] = useState('');
  const [senha,setSenha] = useState('');
  
  const {msgErro} = useContext(MessageContext);
  const {loadValue} = useContext(LoadContext);

  function valueHandler (event)  {
    event.preventDefault();
    if (event.target.name === 'usuario') setUsuario(event.target.value);
    if (event.target.name === 'senha') setSenha(event.target.value);
  };

  const submitHandler = async () => {

        if (usuario === null || senha === null || usuario.length === 0 || senha.length === 0) {
            msgErro('Favor informar Usuario e Senha');
        } 
        else { 
            loadValue(true);
            const url =  getMainUrl() + 'login';
            const body = {};
            body['usuario'] = usuario;
            body['senha'] = senha;
            await new Promise(r => setTimeout(r, 3000));
            await axios.post(url,body)
                .then(response => {
                   localStorage.setItem('token',JSON.stringify(response.data.token));    
                   localStorage.setItem('nomeUsuario',JSON.stringify(response.data.nomeUsuario));    
                }) .catch (e => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('nomeUsuario');
                        msgErro('Usuario ou senha inválidos ' + e);
                });
            loadValue(false);
        }
    };
        
  if (localStorage.getItem('token') != null) {
      return (
          <Venda></Venda>
      )
  }  
  else {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Identificação
        </Typography>
        <form className={classes.form} noValidate >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="usuario"
            label="usuário"
            name="usuario"
            autoComplete="usuario"
            value={usuario}
            onChange={valueHandler}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="senha"
            label="senha"
            type="password"
            id="password"
            autoComplete="senha-atual"
            value={senha}
            onChange={valueHandler}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitHandler}
          >
            Entrar
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
  }
}