import React ,{createContext, useReducer} from 'react';

const INITIAL_VALUE = {loading: false, token:''};

const LoadContext = createContext();

export const LoadProvider = ({children}) => {

    const [state,dispacher] = useReducer((state,action) => {
        const context  = {
            loading: action.loading,
        };
        return context;
    },INITIAL_VALUE);

    const loadValue = loading => dispacher({loading});

    return (
        <div>
            <LoadContext.Provider value={{loadValue,state}}>,
                {children}
            </LoadContext.Provider>
        </div>
    );
};

export default LoadContext;
