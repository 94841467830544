const getMainUrl = () => {

    const urlApiBase = 'https://www.simmc.com.br/cdn13410/';

    return urlApiBase.toString();

}

export default getMainUrl;

