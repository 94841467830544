import React ,{useState} from 'react';
import {Link} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import {makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { useSearch } from './searching/search';

import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: (theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function SimmcToolbar() {
  const classes = useStyles();
  const [open,setOpen] = useState(false);
  const {setSearchTxt} = useSearch();

  function toogleOpen() {
      setOpen(!open);
  }

  function onChangeSearch(event) {
      if (event.target.value.length > 0)  {
        setSearchTxt(event.target.value);
      }

  } 

  return (
    <div className={classes.root}>
      <AppBar style={{position:"fixed"}} >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={toogleOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            SIMMC
          </Typography>
          <div >
          <div className={classes.search}>
            <div  className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Pesquisa..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={onChangeSearch}
            />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <br/><br/>
      <div onClick={toogleOpen}>
      <Drawer open={open}>
        <MenuItem><Link to="/inicio">Home</Link></MenuItem>
        <Divider/>
        <MenuItem><Link to="/inicio">Minhas Vendas</Link></MenuItem>
        <MenuItem><Link to="/venda">Venda Por Vendedor</Link></MenuItem>
        <MenuItem><Link to="/pedido">Pedidos</Link></MenuItem>
        <Divider/>
        <MenuItem><Link to="/login">Login</Link></MenuItem>
        <MenuItem><Link to="/sair">Sair</Link></MenuItem>
      </Drawer> 
      <br/><br/>
      </div>

    </div>
  );
}