import React from 'react';  
import { BrowserRouter , Routes, Route, Outlet} from 'react-router-dom';

import Login from './pages/Login/index';
import Logout from './pages/Logout/index';
import VendaPorVendedor from './pages/Venda/VendaPorVendedor';
import Venda from './pages/Venda';
import Pedido from './pages/Pedido';
import EditarPedido from './pages/Pedido/EditarPedido/index.js';
import SimmcToolbar from './SimmcToolbar';
import Msg from './messaging/Msg';
import PrivateRoutes from './commons/PrivateRoutes';
import { SearchProvider } from './searching/search';
import {MessageProvider} from './messaging/message';
import { LoadProvider } from './loading/load';
import LoadTheme from './loading/LoadTheme';

const  App =  () =>  {
    
  function  Raiz()  {
    return (
      <div>
          <LoadProvider>
          <LoadTheme/>
              <MessageProvider>
                <SearchProvider>
                    <SimmcToolbar/>
                    <Outlet/>
                </SearchProvider>      
                <Msg/>
              </MessageProvider>
          </LoadProvider>
      </div>
    )
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path='/'  element={<Raiz/>}>
              <Route path='pedido' element={<PrivateRoutes><Pedido/></PrivateRoutes>}/>
              <Route path='edit'  element={<PrivateRoutes><EditarPedido/></PrivateRoutes>} />
              <Route path='inicio' element={<PrivateRoutes><Venda/></PrivateRoutes>} />
              <Route path='login' element={<Login/>} />
              <Route path='sair' element={<Logout/>} />
              <Route path='venda' element={<PrivateRoutes><VendaPorVendedor/></PrivateRoutes>} />
            </Route>
        </Routes>
      </BrowserRouter>          
    </div>
  )
}

export default App;
