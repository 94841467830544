import {React} from 'react';
import {Radio,RadioGroup,FormControl,FormControlLabel,FormLabel} from '@material-ui/core';

const StatusPagamento = (params) => {

    const handleChange = event => {
        params.pedido.statusPagamento = event.target.value;
    }    

    return (
        <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">STATUS PAGAMENTO</FormLabel>
                <RadioGroup defaultValue={params.pedido.statusPagamento} row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group">
                <FormControlLabel onChange={handleChange} value="Receber" control={<Radio />} label="RECEBER" />
                <FormControlLabel onChange={handleChange} value="Pago" control={<Radio />} label="PAGO" />
                </RadioGroup>
        </FormControl>          
    );
}

export default StatusPagamento;