import React from 'react';
import {TextField} from '@material-ui/core';

import useStyles from '../../../utilJs/styles.js';

const DadosPessoais = (params) => {

    const classes=useStyles();

    const handleChange = event => {
        params.pedido[event.target.id] = event.target.value;
    }    

    return (
        <div>
            <TextField  defaultValue={params.pedido.nomeCliente}
                        onChange={handleChange} 
                        multiline={true} fullWidth={true} required  
                        variant='outlined' floatinglabeltext="Nome"  id="nomeCliente" label="Nome" />
            <br/>
            <TextField  defaultValue={params.pedido.telefoneCelular} 
                        onChange={handleChange} 
                        className={classes.top10Right10}
                        variant='outlined' floatinglabeltext="Celular" id="telefoneCelular" label="Celular"/>
            &nbsp;&nbsp;&nbsp;
            <TextField  defaultValue={params.pedido.telefoneFixo} 
                        onChange={handleChange} 
                        className={classes.top10Right10}
                        variant='outlined' floatinglabeltext="Telefone Fixo" id="telefoneFixo" label="Telefone Fixo" />
            <br/>
            <TextField  defaultValue={params.pedido.observacaoEntrega} 
                        onChange={handleChange} 
                        multiline={true} fullWidth={true} 
                        className={classes.top10}
                        variant='outlined' type='string' floatinglabeltext="Observacao na Entrega" id="observacaoEntrega" label="Observacao na entrega"/>
            <br/>
        </div>
    );
}

export default DadosPessoais;