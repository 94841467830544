import React ,{createContext, useContext, useState, useEffect} from 'react';
import axios from 'axios';
import MessageContext from '../../../messaging/message';
import LoadContext from '../../../loading/load';
import getToken from '../../../utilJs/utilToken.js';
import getMainUrl from '../../../utilJs/utilUrl.js';

const ListaPedidoContext = createContext();

export const useListaPedido = () => useContext(ListaPedidoContext);

export const ListaProvider = ({children}) => {

    const {msgErro,msgInfo} = useContext(MessageContext);
    const {loadValue} = useContext(LoadContext);

    const token = getToken();
    const [pedidos,setPedidos] = useState([]);
    const [bairros,setBairros] = useState([]);
    const [editionOption,setEditionOption]= useState(false);

    const value = {pedidos,setPedidos,bairros,setBairros,editionOption,setEditionOption};

    useEffect( () =>   {buscarPedidos();},[]);
    useEffect( () =>   {buscarBairros();},[]);

    const buscarPedidos = async (cb=()=>{}) => {
        loadValue(true);
        try {
            const url = getMainUrl() + 'venda/buscarPedidosDoMes';
            await new Promise(r => setTimeout(r, 5000));
            const dto = await axios( {method: 'GET', url: url , headers: {'token' : token}});
            setPedidos(dto.data);
            cb();
        } catch(e) {
            if (e.response) {
                if (e.response.status === 401) {
                    localStorage.removeItem('token');    
                    msgInfo('Sessão Expirada - Faça Login novamente');
                    loadValue(false);
                }
            }
                else {
                msgErro('Erro na obtencao dos dados (' + e + ')');
                loadValue(false);
            }
            cb(e);
        }
        //await new Promise(r => setTimeout(r, 10));
        loadValue(false);
    };

    const buscarBairros = async (cb=()=>{}) => {
        loadValue(true);
        try {
            const url = getMainUrl() + 'venda/buscarBairros';
            await new Promise(r => setTimeout(r, 5000));
            const dto = await axios( {method: 'GET', url: url , headers: {'token' : getToken()}});
            setBairros(dto.data);
            cb();
        } catch(e) {
            if (e.response) {
                if (e.response.status === 401) {
                    localStorage.removeItem('token');    
                    msgInfo('Sessão Expirada - Faça Login novamente');
                }
            }
            else {
                    msgErro('Erro na obtencao dos dados (' + e + ')');
            }
            cb(e);
        }
        loadValue(false);
    };

    return (
        <ListaPedidoContext.Provider value={value}>
            {children}
        </ListaPedidoContext.Provider>
    );
};
