import React from 'react';

import { ListaProvider } from './Context';
import ListaPedido from './ListaPedido';

const  Pedido = ()  => {

    return (
        
        <ListaProvider>
            <ListaPedido/>
        </ListaProvider>    
        
    )

}

export default Pedido;
