import React from 'react';
import {TextField} from '@material-ui/core';

import useStyles from '../../../utilJs/styles.js';

const Sabores = (params) => {

    const classes = useStyles();

    const handleChange = event => {
        params.pedido[event.target.id] = event.target.value;

        if (event.target.id === 'qtdEspecial' && params.pedido.qtdEspecial > 0 && params.pedido.observacao === '') {
            document.getElementById("observacao").focus();
        }
    }    

    return (
        <div>
            <TextField  defaultValue={params.pedido.qtdMista}
                        type="number"
                        onChange={handleChange} 
                        className={classes.top10Right10}
                        variant='outlined' floatinglabeltext="Mista"  id="qtdMista" label="Mista" />
            <TextField  defaultValue={params.pedido.qtdMarguerita} 
                        type="number"
                        onChange={handleChange} 
                        className={classes.top10Right10}
                        variant='outlined' floatinglabeltext="Marguerita" id="qtdMarguerita" label="Marguerita"/>
            <TextField  defaultValue={params.pedido.qtdPresunto} 
                        type="number"
                        onChange={handleChange} 
                        className={classes.top10Right10}
                        variant='outlined' floatinglabeltext="Presunto Fixo" id="qtdPresunto" label="Presunto" />
            <TextField  defaultValue={params.pedido.qtdEspecial} 
                        type="number"
                        onChange={handleChange} 
                        className={classes.top10Right10}
                        variant='outlined' floatinglabeltext="Especial" id="qtdEspecial" label="Especial"/>
            <br/>
            <TextField  defaultValue={params.pedido.observacao} 
                        onChange={handleChange} 
                        fullWidth={true}
                        className={classes.top10Right10}
                        variant='outlined' type='string' floatinglabeltext="Observacao Especial" id="observacao" label="Observacao Pizza Especial"/>
        </div>    
    )


}

export default Sabores;