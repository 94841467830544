import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import MessageContext from './message';

export default () => (
    <MessageContext.Consumer>{(context) => {
        const {state,msgClose} = context;
        if (state.texto.length === 0) {
            return null;
        } else {
        return (
            <Snackbar 
                open={state.open} 
                autoHideDuration={state.time} 
                onClose={msgClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert 
                    elevation={6} 
                    variant='filled' 
                    onClose={msgClose} 
                    severity={state.severity}
                >
                    {state.texto}
                </Alert>
            </Snackbar>
        );
        }
    }}</MessageContext.Consumer>
);