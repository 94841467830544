function createCheckSum(primaryToken) {

    const twoFirstCharacters = getStringRandom(")!@#$%?&*(",2);
    return twoFirstCharacters + getChecksum(primaryToken,twoFirstCharacters);

}

function getChecksum(primaryToken, twoFirstCharacters) {

    const firstChange = getIndiceSubstr(twoFirstCharacters.substring(0,1));
    const eachChange = getIndiceSubstr(twoFirstCharacters.substring(1,2));

    let codeCheckSum = "";

    let charChange = firstChange;

    var i;
    for (i=2; i<10; i++ ) {
        codeCheckSum += primaryToken.substring(charChange,charChange+1);
        charChange += eachChange;
    }

    return codeCheckSum;

}

function getStringRandom(charDisp,length) {

    let stringRandom =  "";

    var i;
    for (i = 0; i < length; i++) {
        var substr = getNumberRandom(0,9);
        stringRandom += charDisp.substring(substr,substr+1);
    }

    return stringRandom;
}

function getNumberRandom(min,max) {
    return min + (Math.random() * ((max - min) + 1));
}

function getIndiceSubstr(text) {

    switch (text) {
        case ")":  return 9;
        case "!":  return 8;
        case "@":  return 7;
        case "#":  return 6;
        case "$":  return 5;
        case "%":  return 4;
        case "?":  return 3;
        case "&":  return 2;
        case "*":  return 1;
        case "(":  return 0;
        default : return 0;
    }
}

const  getToken = () => {

    var primaryToken = localStorage.getItem('token');

    if (primaryToken === null || primaryToken.length === 0) 
        return 'no-token-available';

    primaryToken = primaryToken.substring(1);
    primaryToken = primaryToken.substring(0,primaryToken.length-1);

    var finalToken = createCheckSum(primaryToken)+primaryToken;
    
    //alert('final token ' + finalToken);
    return finalToken;

}

export default getToken;

