import React, {useEffect} from 'react';
import axios from 'axios';

import Login from '../../pages/Login';
import getToken from '../../utilJs/utilToken.js';
import getMainUrl from '../../utilJs/utilUrl.js';

export default  ({children}) => {

    useEffect( () => {buscar();},[]);

    const buscar = async (cb=()=>{}) => {

        if (localStorage.getItem('token')) {
            try {
                const url = getMainUrl() + 'valida-token';
                await new Promise(r => setTimeout(r, 3000));
                await axios( {method: 'POST', url: url , headers: {'token' : getToken()}});
                cb();
            } catch(e) {
                localStorage.removeItem('token');    
                cb(e);
            }
        }
    };

    if (localStorage.getItem('token')) {
        return (
            <div>{children}</div>
        )
    } 

    return (
        <Login/>
    )

}

