import React from 'react';
import Login from '../Login/index';

export default function Logout() {

localStorage.removeItem('token');
localStorage.removeItem('nomeUsuario');

return (<Login></Login>)

}