import React ,{createContext, useContext, useState} from 'react';

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({children}) => {

    const [searchTxt,setSearchTxt] = useState('');

    const value = {searchTxt,setSearchTxt};

    return (
        <SearchContext.Provider value={value}>
            {children}
        </SearchContext.Provider>
    );
};
