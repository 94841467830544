import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';

import {List,Avatar,ListItem,ListItemAvatar,ListItemText,ListSubheader, Divider} from '@material-ui/core';

import MessageContext from '../../messaging/message';
import LoadContext from '../../loading/load';

import getToken from '../../utilJs/utilToken.js';
import getMainUrl from '../../utilJs/utilUrl.js';
import styles from '../../utilJs/css.js';

const  ItemLista  = props =>  {

    let rows = null;

    if (props.data.data) {
        rows = props.data.data.map( item => 
            {
                return (
                    <div key={item.idVendedor}>
                    <ListItem alignItems="center" key={item.idVendedor}> 
                    <ListItemAvatar>
                        <Avatar style={styles.avatar}>
                            {item.qtdVendida}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                            secondary={item.nomeVendedor}
                    />
                    </ListItem>
                    <Divider/>
                    </div>

                )
            }    
        )
    }

    return <List>{rows}</List>
}

const  VendaPorVendedor = (props)  => {

    const {msgErro,msgInfo} = useContext(MessageContext);
    const {loadValue} = useContext(LoadContext);

    const [dados,setData] = useState([]);

    const token = getToken();

    useEffect( () => {buscar();},[]);

    const buscar = async (cb=()=>{}) => {

            loadValue(true);
            try {
                const url = getMainUrl() + 'venda/buscarVendaPorVendedor';
                await new Promise(r => setTimeout(r, 3000));
                const lista = await axios( {method: 'GET', url: url , headers: {'token' : token}});
                setData(lista);
                cb();
            } catch(e) {
                if (e.response) {
                    if (e.response.status === 401) {
                        localStorage.removeItem('token');    
                        msgInfo('Sessão Expirada - Faça Login novamente');
                    }
                }
                else {
                    msgErro('Erro na obtencao dos dados (' + e + ')');
                }
                cb(e);
            }
            loadValue(false);
    };

    return (
        <div>
            <Divider/>
            <List
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Venda Por Vendedor
                    </ListSubheader>
                }
            />
            <Divider/>
            <ItemLista data={dados} />
        </div>)
};

export default VendaPorVendedor
