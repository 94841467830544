import React from 'react';

import LoadContext from './load';

import LinearProgress from '@material-ui/core/LinearProgress';

export default () => (
    
    <LoadContext.Consumer>{(context) => {
        const style = context.state.loading ? { opacity: '0.2', pointerEvents: 'none' } : {};
        return (
          context.state.loading &&   
                <div style={style} align='center'>
                        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                        <LinearProgress />
                        <LinearProgress color='secondary'/>
                </div>
        );

    }}</LoadContext.Consumer>
)

